import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import UserContext from './UserContext';
import ReviewSubmissionInvalidForm from './ReviewSubmissionInvalidForm';
import ReviewSubmissionValidForm from './ReviewSubmissionValidForm';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, ButtonGroup, Typography, Container, CircularProgress, TextField } from '@mui/material';

const ReviewSubmission = () => {
  const [loading, setLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const { db, storage } = useContext(UserContext);
  const [submission, setSubmission] = useState(null);
  const { submissionId } = useParams();
  const [invalidReason, setInvalidReason] = useState('');
  const [videoAngle, setVideoAngle] = useState('');
  const videoRef = useRef(null);
  const [isVideoFullWidth, setIsVideoFullWidth] = useState(true);
  const [highlights, setHighlights] = useState([{ text: '', isGood: true }]);
  const [tips, setTips] = useState([{ title: '', description: '' }]);
  const [symptomsSummary, setSymptomsSummary] = useState('');
  const [timestampImages, setTimestampImages] = useState({
    side: {
      LR: '',
      MSt: '',
      LSt: '',
      MSw: '',
    },
    back: {
      MSt: { left: '', right: '' },
      IC: { left: '', right: '' },
      MSw: { left: '', right: '' },
    },
  });

  const [data, setData] = useState({
    side: {
      LR: {
        footStrikeAngle: undefined,
        tibiaAngle: undefined,
        degreeOfOverstriding: undefined,
      },
      MSt: {
        kneeFlexionAngle: undefined,
        heelHeight: undefined,
        verticalDisplacement: undefined,
      },
      LSt: {
        trunkLeanAngle: undefined,
        hipExtensionAngle: undefined,
      }
    },
    back: {
      IC: {
        pelvicDrop: { left: undefined, right: undefined },
        heelEversion: { left: undefined, right: undefined },
      },
      MSt: {
        baseOfSupport: undefined,
        kneeWindow: undefined,
        toeOutAngle: { left: undefined, right: undefined },
        heelHeight: { left: undefined, right: undefined },
        verticalDisplacement: { left: undefined, right: undefined },
      },
      MSw: {
        heelWhipAngle: { left: undefined, right: undefined },
      },
    },
    cadence: undefined,
    treadmillShakingDegree: undefined,
  });

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Forma | Review Submission";

    const fetchSubmissionData = async () => {
      try {
        const submissionDocRef = doc(db, 'submissions', submissionId);
        const submissionDocSnapshot = await getDoc(submissionDocRef);

        if (submissionDocSnapshot.exists()) {
          setSubmission({ id: submissionDocSnapshot.id, ...submissionDocSnapshot.data() });
        } else {
          console.error('Submission not found:', submissionId);
        }
      } catch (error) {
        console.error('Error fetching submission:', error);
        toast.error('Error fetching submission. Please retry.', { toastId: 'reviewError' });
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissionData();
  }, [db, submissionId]);

  useEffect(() => {
      const handleKeydown = (e) => {
        let seekAmount = e.shiftKey ? 0.5 : 0.01;

        if (e.keyCode === 37) {  // Left arrow key
          videoRef.current.currentTime -= seekAmount;
        } else if (e.keyCode === 39) {  // Right arrow key
          videoRef.current.currentTime += seekAmount;
        }
      };

      window.addEventListener('keydown', handleKeydown);

      return () => {
        window.removeEventListener('keydown', handleKeydown);
      };
    }, []);

  const handleInvalid = async (event) => {
    event.preventDefault();
    try {
       await updateDoc(doc(db, 'submissions', submissionId), {
         status: 'invalid',
         feedback: invalidReason,
         symptomsSummary: symptomsSummary ? symptomsSummary : null,
       });
       toast.success("Submission has been marked as invalid.");
       navigate('/admin');
     } catch (error) {
       console.error('Error updating document:', error);
       toast.error("Error updating document. Please try again.");
     }
  };

  const handleValid = async (event) => {
    event.preventDefault();
    try {
      setIsUploading(true);
      const decodedVideoUrl = decodeURIComponent(submission.videoUrl);
      const videoPath = new URL(decodedVideoUrl).pathname;
      const basePath = videoPath.split('/o/')[1];

      const phases = timestampImages[videoAngle];

      for (const [phase, imageData] of Object.entries(phases)) {
        const path = `${basePath}_${phase}`;
        const imageUrl = (videoAngle === "side") ? imageData : imageData.left;
        const blob = await (await fetch(imageUrl)).blob();
        await uploadBytes(ref(storage, path), blob);
      }

      await updateDoc(doc(db, 'submissions', submissionId), {
         status: 'reviewed',
         videoAngle: videoAngle,
         symptomsSummary: symptomsSummary ? symptomsSummary : null,
         results: {
           sideViewData: videoAngle === 'side' ? data.side : null,
           backViewData: videoAngle === 'back' ? data.back : null,
           cadence: data.cadence,
           treadmillShakingDegree: data.treadmillShakingDegree || null,
           highlights: highlights,
           tips: tips
         },
       });
      toast.success("Submission has been marked as reviewed.");
      navigate('/admin');
    } catch (error) {
      console.error('Error updating document:', error);
      toast.error("Error updating document. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  const handlePlaybackRate = (rate) => {
    if (videoRef.current) {
      videoRef.current.playbackRate = rate;
    }
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" align="center" gutterBottom>
        Review Submission
      </Typography>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </Box>
      ) : !submission ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <Typography variant="h6">No submission data found.</Typography>
        </Box>
      ) : (
        <>
          <Box mb={1}>
            <video
              crossOrigin="anonymous"
              controls
              loop
              ref={videoRef}
              style={{
                width: isVideoFullWidth ? "100%" : "50%",
                transition: "width 0.3s ease",
              }}
            >
              <source src={submission.videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Box display="flex" justifyContent="start" mt={1} alignItems="center">
              <Button variant="contained" size="small" onClick={() => setIsVideoFullWidth(!isVideoFullWidth)}>
                {isVideoFullWidth ? "Shrink to 50%" : "Expand to Full Width"}
              </Button>
              <ButtonGroup size="small" variant="contained" sx={{ ml: 2 }}>
                <Button onClick={() => handlePlaybackRate(1)}>1x</Button>
                <Button onClick={() => handlePlaybackRate(2)}>2x</Button>
                <Button onClick={() => handlePlaybackRate(3)}>3x</Button>
                <Button onClick={() => handlePlaybackRate(4)}>4x</Button>
                <Button onClick={() => handlePlaybackRate(8)}>8x</Button>
              </ButtonGroup>
            </Box>
          </Box>
          <Typography variant="body1">User ID: {submission.userId}</Typography>
          <Typography variant="body1">Timestamp: {new Date(submission.timestamp?.seconds * 1000).toLocaleString()}</Typography>
          <Typography variant="body1">Goal: {submission.goal}</Typography>
          <Typography variant="body1">Status: {submission.status}</Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="body1" mr={2}>
              Symptoms: {submission.symptomsText ? submission.symptomsText : 'None'}
            </Typography>
            <TextField
              label="Summarize, e.g. 'Left knee'"
              variant="outlined"
              size="small"
              value={symptomsSummary}
              onChange={(e) => setSymptomsSummary(e.target.value)}
              disabled={!submission.symptomsText}
              style={{ flexGrow: 1 }}
            />
          </Box>
          <Box mt={4}>
            <Typography variant="h5">Admin Review</Typography>
            <ReviewSubmissionInvalidForm
              invalidReason={invalidReason}
              setInvalidReason={setInvalidReason}
              handleInvalid={handleInvalid}
            />
            <ReviewSubmissionValidForm
              videoRef={videoRef}
              videoAngle={videoAngle}
              setVideoAngle={setVideoAngle}
              timestampImages={timestampImages}
              setTimestampImages={setTimestampImages}
              data={data}
              setData={setData}
              handleValid={handleValid}
              highlights={highlights}
              setHighlights={setHighlights}
              tips={tips}
              setTips={setTips}
              isUploading={isUploading}
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default ReviewSubmission;
